body {
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Roboto",
    "Segoe UI", "Helvetica Neue", sans-serif;
  background-color: #ffffff;
}

/* Scrollbar*/
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: rgb(22, 106, 234);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
