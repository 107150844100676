body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

footer {
  background-color: #0e1012;
  color: #fff;
  padding: 40px 0;
  position: relative;
  overflow: auto;
  border-top: 1px solid #333;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.footer-column {
  flex: 1 1 200px;
  margin: 15px;
  position: relative;
  min-width: 180px;
  transition: transform 0.3s ease-in-out;
}

.footer-column:hover {
  transform: translateY(-5px);
}
.social-media-p {
  display: flex;
  flex-direction: column;
  gap: 55px;
}
.footer-column h5 {
  border-bottom: 2px solid #444;
  padding-bottom: 12px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.footer-column p,
.footer-column ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.95rem;
}

.footer-column ul li {
  margin-bottom: 10px;
  color: #ccc;
}

.footer-column ul li a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.footer-column ul li a:hover {
  color: #fff;
  text-shadow: 0 0 1px rgb(22, 106, 234);
}

.footer-column .social-media {
  display: flex;
  gap: 20px;
}

.footer-column .social-media a {
  text-decoration: none;
  display: flex;
}

.footer-column .social-media a:hover {
  transition: 0.3s ease;
}

.fa-xl {
  color: white;
}

.footer-column .subscribe {
  display: flex;
  flex-direction: column;
}

.footer-column .subscribe input[type="email"],
.footer-column .subscribe input[type="text"] {
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 10px;
  border: black;
  outline: none;
  font-size: 0.95rem;
  transition: border-color 0.3s ease;
}

.footer-column .subscribe input[type="email"]:focus,
.footer-column .subscribe input[type="text"]:focus {
  border: black;
}

.footer-column .subscribe button {
  padding: 12px;
  border: none;
  border-radius: 10px;
  background-color: rgb(22, 106, 234);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1rem;
}

.footer-column .subscribe button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}

.footer-bottom {
  /* text-align: center; */
  padding: 15px 0;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.95rem;
  opacity: 0.8;
}

.footer-links {
  display: flex;
  align-items: center;
}

.footer-link {
  font-size: 15px;
  color: rgb(22, 106, 234);
  margin: 0 5px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: rgb(36, 122, 250);
}

/* Responsive adjustments */

@media (max-width: 1000px) {
  .footer-bottom {
    flex-direction: column;
    gap: 10px;
  }
  .footer-links {
    gap: 10px;
  }
  .footer-link {
    margin: 5px 0;
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .footer-bottom {
    flex-direction: column;
    gap: 10px;
  }
  .footer-links {
    gap: 10px;
  }

  .footer-link {
    margin: 5px 0;
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin: 20px 0;
    text-align: center;
    min-width: 300px;
    flex: 1 1 100%;
  }

  .footer-column .social-media {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
