.signin-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.signin-content {
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  background: linear-gradient(135deg, #0a2e73, #6a5acd, #8a2be2);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.signin-content:hover {
  transform: translateY(-5px);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.4); /* Adds more depth on hover */
}

.intro-section {
  margin-bottom: 30px;
}

.intro-heading {
  font-size:2.8rem;
  margin-bottom: 10px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

.intro-paragraph {
  font-size: 16px;
  line-height: 1.6;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.signin-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-container {
  display: inline-block;
}

.signin-btn {
  padding: 12px;
  border: none;
  border-radius: 10px;
  background:linear-gradient(135deg, #013698, hsl(275, 97%, 36%), #8a2be2);
  box-shadow: 
  1px 4px 10px rgba(13, 77, 194, 0.5), 
  1px 8px 20px rgba(106, 3, 180, 0.4),  
  1px 12px 30px rgba(138, 43, 226, 0.3); 
  color: #fff;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  font-size: 1rem;
}

.signin-btn:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}

.signin-btn span {
  font-size: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* background-color: #fff; */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.modal-close-btn {
  background-color: transparent;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}



/* Dark Theme */
.dark-mode .signin-section{
  background-color: #010409;
  color: white;
}

.dark-mode .signin-content {
  background-color: #161b22;
  color: white;
  border: 1px solid #333;

}

.dark-mode .intro-heading {
  color: #ffffff;
}

.dark-mode .intro-paragraph {
  color: #ddd;
}


/* Responsive Design */
@media (max-width: 768px) {
  .signin-section {
    padding: 30px;
  }

  .signin-content {
    padding: 20px;
  }

  .intro-heading {
    font-size: 24px;
  }

  .intro-paragraph {
    font-size: 14px;
  }

  .signin-btn {
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .signin-section {
    padding: 20px;
  }

  .signin-content {
    padding: 15px;
  }

  .intro-heading {
    font-size: 20px;
  }

  .intro-paragraph {
    font-size: 13px;
  }

  .signin-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}
