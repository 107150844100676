.like-container_mainPage {
  display: inline-block;
  padding-left: 20px;
}

.like-button_mainPage {
  border: none;
  color: rgb(22, 106, 234);
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.like-button_mainPage:hover {
  transform: translateY(-3px);
}

.like-button_mainPage:active {
  transform: translateY(1px);
}

/* new */

/* Popup styling */
.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
  width: 300px;
}

.popup p {
  margin: 0 0 15px;
  font-size: 16px;
  color: #333;
}

.popup button {
  background-color: #166aea;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.popup button:hover {
  background-color: #0b57d0;
}
