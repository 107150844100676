.team-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  margin-top: 2%;
  margin-bottom: 3%;
}

.team-header {
  text-align: center;
  margin-bottom: 2rem;
}

.team-header-content {
  /* max-width: 800px; */
  margin: 2rem auto;
}

.team-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: black;
  margin-bottom: 1rem;
}

.team-header p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
}

.team-member-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.team-member-card {
  width: 300px;
  height: 350px;
  perspective: 1000px;
  border-radius: 10px;
}

.card-content {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  cursor: pointer;
}

.team-member-card:hover .card-content {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 0.3s;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.front {
  padding: 1rem;
  text-align: center;
}

.front img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.front h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.5rem;
}

.front p {
  font-size: 1rem;
  color: #666;
}

.back {
  background: #cadbf3;
  color: black;
  padding: 1rem;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.back p {
  font-size: 1rem;
  text-align: center;
  line-height: 1.4;
}

.linkedin-icon {
  color: rgb(10, 102, 194);
  font-size: 2rem;
  transition: transform 0.3s ease-in-out;
}

.linkedin-icon:hover {
  transform: scale(1.2);
}

.founder-container {
  display: flex;
  margin: 2rem auto;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform 0.3s ease-in-out;
  background-color: rgb(255, 250, 251);
}

.founder-container:hover {
  transform: scale(1.02);
}

.founder-image {
  flex: 1;
  margin-right: 1rem;
}

.founder-image img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.founder-image img:hover {
  transform: scale(1.05);
}

.founder-name h2{
  font-weight: bold;
  font-size: 1.4rem;
  /* margin: auto 80px; */
  letter-spacing: 0.4px;
  width: 85%;
  text-align: center;
  margin-top: 10px;

}

.founder-name p {
  font-size: 1rem;
  /* margin: auto 50px; */
  letter-spacing: 0.4px;
  width: 85%;
  text-align: center;
}

.founder-text {
  flex: 2;
  padding: 1rem;
}

.founder-text h2 {
  font-size: 2rem;
  font-weight: 700;
  color: black;
  margin-bottom: 1rem;
  text-align: center;
}

.founder-text p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  text-align: center;
}

/* dark theme */

.dark-mode .team-section {
  background-color: #010409;
}

.dark-mode .founder-container {
  background-color: #161b22;
  box-shadow: 2px 0 8px rgba(255, 255, 255, 0.3);
  border: 1px solid #333;
}

.dark-mode .founder-text h2,
.dark-mode .gradient-text,
.dark-mode .founder-name h2 {
  color: white;
}

.dark-mode .founder-text p,
.dark-mode .team-header p,
.dark-mode .founder-name p {
  color: #ccc;
}

.dark-mode .front {
  background-color: #161b22;
  border: 1px solid #333;
}

.dark-mode .front p {
  color: #ccc;
}

.dark-mode .back {
  background: #161b22;
  color: #ccc;
  border: 1px solid #333;
}

/* responsiveness */
@media (max-width: 1175px) {
  .founder-name h2{
    width: 100%;
  }
  
  .founder-name p {
    width: 100%;
  }
}
@media (max-width: 960px) {
  .team-member-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}
@media (max-width: 768px) {
  .team-header h1 {
    font-size: 2rem;
  }

  .team-header p {
    font-size: 1rem;
  }

  .team-member-card {
    width: 200px;
    height: 250px;
  }
  .founder-container {
    flex-direction: column;
  }
  .founder-name h2 {
    margin: 0px;
  }
  .founder-image {
    margin: 0;
    margin-bottom: 1rem;
  }

  .founder-text {
    padding: 0;
  }

  .founder-text h2 {
    font-size: 1.5rem;
  }

  .founder-text p {
    font-size: 1rem;
  }
  .front img {
    width: 70%;
    height: 70%;
  }
}

@media (max-width: 480px) {
  .team-header h1 {
    font-size: 1.5rem;
  }

  .team-header p {
    font-size: 0.9rem;
  }

  .team-member-card {
    width: 260px;
    height: 300px;
  }

  .front img {
    width: 70%;
    height: 60%;
  }

  .front h2 {
    font-size: 1.2rem;
  }
  .founder-text h2 {
    font-size: 1.2rem;
  }

  .founder-text p {
    font-size: 0.9rem;
  }
  .team-member-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
