/* General styles for the body */
body {
  font-family: "Roboto", sans-serif;
  color: #2c3e50;
  background: linear-gradient(to bottom, #f9f9f9, #eef2f3);
  margin: 0;
  padding: 0;
}

/* Container styling */
.privacy-container {
  background: #ffffff;
  border-radius: 12px;
  padding: 40px;
  max-width: 900px;
  margin: 40px auto;
  line-height: 1.8;
}

/* Heading styles */
.privacy-heading {
  font-size: 2rem;
  color: #34495e;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
}

.subheading {
  font-size: 1.5rem;
  color: #16a085;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 600;
  padding-left: 12px;
}

.subheading2 {
  font-size: 1.3rem;
  color: #2980b9;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  padding-left: 10px;
}

/* Paragraph styles */
.privacypara {
  font-size: 1.1rem;
  color: #555;
  margin: 10px 0 20px;
}

/* List styles */
.privacypoint {
  padding-left: 20px;
  margin: 10px 0 20px;
  list-style: none;
}

.privacysubpoint {
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
  font-size: 1rem;
  color: #444;
}

.privacysubpoint::before {
  content: "•";
  color: #16a085;
  position: absolute;
  left: 0;
  font-size: 1.5rem;
  line-height: 1;
}

/* Link styles */
.privacylink {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.privacylink:hover {
  color: #1abc9c;
  text-decoration: underline;
}

/* Highlight important text */
strong {
  color: #2c3e50;
  font-weight: 600;
}

/* Contact section styles */
ul li strong {
  color: #2c3e50;
  font-weight: 700;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .privacy-container {
    padding: 20px;
  }

  .privacy-heading {
    font-size: 1.8rem;
  }

  .subheading {
    font-size: 1.3rem;
  }

  .subheading2 {
    font-size: 1.1rem;
  }

  .privacypara,
  .privacysubpoint {
    font-size: 0.95rem;
  }
}
