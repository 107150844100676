.modal-overlay {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #222;
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  color: #fff;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}


.modal-content h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #fff;
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.form-row input {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #444;
  background: #333;
  color: #fff;
}

.modelEmail,
textarea {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #444;
  background: #333;
  color: #fff;
  margin-bottom: 20px;
}

textarea {
  height: 100px;
}

.form-group p {
  margin-bottom: 10px;
  font-size: 1em;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
}

.form-group input[type="radio"] {
  margin-right: 10px;
}

.form-group input[type="checkbox"] {
  margin-right: 10px;
}

.model_submit_button {
  width: 100%;
  padding: 15px;
  background: #0a84ff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
}

.model_submit_button:hover {
  background: #006bbf;
}

@media (max-width: 500px) {
  .form-row {
    flex-direction: column;
  }

  .form-row input {
    width: 100%; 
    margin-bottom: 15px; 
  }
}