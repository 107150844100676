@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

/* Universal Styles */
.home-header-container {
  position: relative;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 3;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  z-index: 3;
  animation: fadeIn 2s ease-in-out;
}

.text-content {
  max-width: 600px;
  padding: 20px;
  animation: slideIn 1.5s ease-in-out;
}

.main-title {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 20px;
  animation: pulse 3s infinite;
}

.sub-title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 30px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.buttons {
  display: flex;
  gap: 15px;
}

.cta-button1 {
  padding: 15px 35px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
}
.cta-link {
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1rem;
  white-space: nowrap;
}

.cta-button1 {
  border: none;
  background-color: rgb(22, 106, 234);
  color: #fff;
}

.cta-button1:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}

.cta-link {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.2rem;
  color: black;
  border: 2px solid black;
  text-decoration: none;
  font-weight: 600;
}

.cta-link:hover {
  background-color: rgb(227, 226, 226);
  transform: translateY(-3px);
}

.animation-content {
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 2s ease-in-out;
}

#circle {
  height: 500px;
  width: 600px;
  opacity: 0.9;
}

.background-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.1) 10%,
    transparent 60%
  );
  transform: translate(-50%, -50%);
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.blurred {
  filter: blur(1.5px); 
  transition: filter 0.3s ease;
}


/* dark theme */

.dark-mode .overlay {
  background: #010409; 
}

.dark-mode .content {
  color: #e3e3e3; 
}

.dark-mode .main-title {
  color: white; 
}

.dark-mode .sub-title {
  color: #ddd; 
  text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.7);
}

.dark-mode .video-section{
  border: 1px solid #333;
}

.dark-mode .cta-link {
  color: white; 
  border: 2px solid white; 
}

.dark-mode .cta-link:hover {
  background-color: #161b22; 
}

/* Tablet and Desktop Styles */
@media (max-width: 1024px) {
  .content {
    flex-direction: column;
    text-align: center;
  }
  
  .text-content {
    max-width: 100%;
    padding: 10px;
  }

  .buttons {
    justify-content: center;
  }
}

@media (max-width: 850px) {
  iframe {
    height: 450px;
  }
}
/* Mobile Styles */
@media (max-width: 768px) {
  .animation-content {
    display: none;
  }

  .main-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .buttons {
    gap: 10px;
    margin-bottom: 20px;
  }

  .cta-button1,
  .cta-link {
    font-size: 1rem; 
    padding: 10px 20px;
  }
}
@media (max-width: 700px) {
  iframe {
    height: 400px; 
  }
}
@media (max-width: 615px) {
  iframe {
    height: 380px; 
  }
}
@media (max-width: 570px){
  iframe {
    height: 350px; 
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .home-header-container {
    padding: 0 10px;
  }

  .main-title {
    font-size: 2rem;
    margin-top: 62px;
  }

  .sub-title {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .cta-button1,
  .cta-link {
    font-size: 0.9rem;
    padding: 12px 20px;
  }

  .buttons {
    gap: 15px;
    flex-direction: column; 
    align-items: center;
  }
  iframe {
    height: 300px; 
  }
}



















