/* General Page Styling */
.career-page {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5%; */
  animation: fadeIn 1.5s ease-in-out;
}

/* Content Styling */
.career-content {
  padding: 70px;
  max-width: 800px;
  text-align: center;
  transform: translateY(-50px);
  animation: slideIn 0.5s ease-out forwards;
}

/* Title Styling */
.career-title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 48px;
  line-height: 62px;
}

/* Subtitle Styling */
.career-subtitle {
  font-size: 1.25rem;
  margin-bottom: 20px;
  color: #28a745;
}

/* Description Styling */
.career-description {
  font-size: 1rem;
  margin-bottom: 30px;
  color: #666;
  display: block;
}

/* Button Styling */
.career-button {
  padding: 12px;
  border: none;
  border-radius: 10px;
  background-color: rgb(22, 106, 234);
  color: #fff;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  font-size: 1rem;
}

.career-button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}

/* Link Styling */
.career-link {
  font-size: 1rem;
  color: rgb(22, 106, 234);
  transition: color 0.3s ease;
  display: block;
  margin-top: 20px;
}

.career-link:hover {
  color: #f32688;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* dark theme */

.dark-mode .career-page{
    background-color: #010409;
    color: white;
}

.dark-mode .career-description {
  color: #ccc;
}

/* 
@media (max-width: 568px) {
  .career-page {
    margin-top: 7%;
  }
}
@media (max-width: 397px) {
  .career-page {
    margin-top: 10%;
  }
} */