/* Background image styling */
/* #bg2 {
  opacity: 0.20;
  z-index: -1;
  position: absolute;
  top: -80%;
  left: 25%;
  height: auto;
  width: 100%;
}
 */
 .dark-mode .home-container{
  background-color: #010409;
 }