.future-energy-management {
  margin: 0 auto;
  padding: 1px;
}

.blog-intro-section {
  display: flex;
  align-items: flex-start;
  margin: 50px 10px 40px 20px;
  gap: 30px;
}

.blog-intro-image {
  flex: 1 1 50%;
  min-width: 400px;
}

.blog-intro-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 0 auto;
}

.blog-intro-text {
  flex: 1 1 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.blog-intro-text h2 {
  font-size: 2.3rem;
  color: black;
  font-weight: bold;
  margin-bottom: 15px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
}

.read-time {
  font-size: 1rem;
  color: #666;
  display: flex;
  align-items: center;
}

.read-time i {
  margin-right: 8px;
  color: #333;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons i {
  color: #333;
}
.content-container {
  display: flex;
  margin-top: 20px;
  gap: 40px;
}

.table-of-contents {
  flex: 1;
  padding: 0px 0px 0px 20px;
  background-color: #ffffff;
  position: sticky;
  top: 100px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 50px;
  z-index: 500;
}

.table-of-contents h3 {
  margin-top: 0;
  font-size: 1.5em;
  color: black;
}

.table-of-contents ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.table-of-contents ul li {
  margin-bottom: 10px;
  position: relative;
}

.table-of-contents ul li a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  transition: color 0.3s;
  font-weight: bold;
  letter-spacing: 0.4px;
}

.main-content {
  flex: 3;
  padding: 20px 40px 0px 20px;
}

.content-section {
  margin-bottom: 40px;
  padding-top: 40px;
  position: relative;
  scroll-margin-top: 120px;
  border-bottom: 1px solid #333;
}
.content-section h3 {
  font-size: 1.9rem;
  font-weight: 550;
  letter-spacing: 0.5px;
}
.content-section h4 {
  font-size: 1.3rem;
  font-weight: 550;
  letter-spacing: 0.5px;
  color: #333;
}
.content-section p {
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  color: #333;
}

.main-p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
}
.blog-item-category {
  font-size: 1rem;
  color: rgb(22, 106, 234);
}

.table-of-contents {
  transition: all 0.3s ease;
}

.toc-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px;
  width: 320px;
}

.toc-toggle span {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  margin-left: -10px;
}

.toc-toggle span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 170%;
  height: 1px;
  background-color: #ccc;
}

.toc-toggle .chevron {
  font-size: 20px;
}

.table-of-contents.expanded ul {
  display: block;
}

.table-of-contents ul li.active a {
  color: rgb(22, 106, 234);
  font-weight: bold;
}

.table-of-contents ul li.active::before {
  content: "•";
  position: absolute;
  left: -10px;
  color: rgb(22, 106, 234);
  font-size: 1rem;
}

/* Expand/Collapse styles */
.table-of-contents.collapsed {
  transition: max-height 0.3s ease;
}

.table-of-contents.expanded {
  max-height: 500px;
  transition: max-height 0.3s ease;
}
.blog-detail-heading {
  text-align: center;
  margin-top: 150px;
  font-size: 2.5rem;
  font-weight: bold;
}

.blog-author,
.blog-date {
  color: #666;
}

.innerimage {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.blog_social_section{
  background-color: #d7e7ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  width: 100%; 
  max-width: 900px; 
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  margin-top: 4%;
}
.blog_social_section_p{
  text-align: center;
  font-size: 2.3rem;
  margin: 30px 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.blog_social_section_content{
  display: flex;
  gap: 20px;
}

/* dark mode */

.dark-mode .future-energy-management {
  background-color: #010409;
}

.dark-mode .blog-intro-text h2,
.dark-mode .read-time,
.dark-mode .read-time i,
.dark-mode .social-icons i,
.dark-mode .content-section h3,
.dark-mode .content-section h4 {
  color: white;
}
.dark-mode .main-p,
.dark-mode .content-section p,
.dark-mode .blog-author,
.dark-mode .blog-date {
  color: #aaa;
}

.dark-mode .table-of-contents {
  background-color: #161b22;
  color: white;
  border: 1px solid #333;
}
.dark-mode .toc-toggle {
  background-color: #161b22;
  color: white;
  border: none;
}
.dark-mode .table-of-contents ul li a {
  color: #ddd;
}

.dark-mode .table-of-contents ul li.active a {
  color: rgb(22, 106, 234);
}

.dark-mode .blog-detail-heading {
  color: white;
}
@media (max-width: 900px) {
  .blog-intro-section {
    flex-direction: column;
    align-items: center;
  }

  .blog-intro-image {
    margin: 0 0 20px 0;
  }

  .content-container {
    flex-direction: column;
  }

  .table-of-contents {
    display: block;
    position: sticky;
    top: 70px;
    margin-left: -1px;
  }
  .toc-toggle span::after {
    display: none;
  }

  .main-content {
    padding: 15px;
  }

  .table-of-contents.expanded {
    display: block;
  }

  .toc-toggle {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    font-size: 1.2em;
    color: black;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: left;
    width: 100%;
    margin-right: 10px;
  }

  .chevron {
    display: inline-block;
    margin-left: auto;
    font-size: 1.5em;
    transition: transform 0.3s ease;
  }

  .table-of-contents ul {
    display: none;
  }

  .table-of-contents.expanded ul {
    display: block;
  }
  .toc-toggle span {
    border: none;
    margin-left: 0;
  }
}
@media (min-width: 769px) {
  .table-of-contents {
    display: block;
  }

  .toc-toggle .chevron {
    display: none;
  }
 
  .blog_social_section_p{
    margin: 30px 0px 10px 0px;
  }
}

@media (max-width: 520px) {

  .blog_social_section_p{
    margin: 0px 0px 10px 0px;
  }

  .blog_social_section_content{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
@media (max-width: 400px) {
  .blog-intro-image {
    flex: 1 1 auto;
    min-width: 100%;
  }

  .blog-intro-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
