.share-button {
  padding: 12px 60px;
  border: none;
  border-radius: 10px;
  background-color: rgb(22, 106, 234);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.1rem;
  margin-bottom: 4%;
}

.share-button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}
.share-button i{
  font-size: 23px;
}

.share_popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.share_popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 450px;
  width: 90%; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.close-button {
  cursor: pointer;
  float: right;
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

.copy-button {
  color: white;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 15px;
  border-radius: 10px;
  background-color: #28a745;
  transition: background-color 0.3s ease, color 0.3s ease;
  min-width: 150px;
  border: none;
}

.copy-button:hover {
  background-color: #1bc643;
  color: #fff;
}

.alert {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 1000;
  animation: fadeOut 1.5s forwards;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@media (max-width: 768px) {
  .share-content p {
    font-size: 1.8rem;
  }

 
  .popup-content {
    width: 95%; 
  }

  .alert {
    font-size: 18px; 
    padding: 8px 16px; 
  }
}

@media (max-width: 480px) {
  .share-content p {
    font-size: 1.5rem; 
  }

  .alert {
    font-size: 15px; 
    padding: 6px 12px; 
  }
}


