.GPTbanner {
  width: 100%;
  background-color: rgb(194, 194, 255); 
  background: linear-gradient(135deg, #00008b, #3c00ff, #4b0082, #8400ff);
  background: linear-gradient(135deg, #1e00a3, #5e00d1, #8400ff);
  position: relative;
  margin-bottom: 3%;
  overflow: hidden;
  cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"%3E%3Ctext x="5" y="30" font-size="30" fill="black"%3E🔍%3C/text%3E%3C/svg%3E'), auto;
}

.GPTbanner-text {
  font-size: 40px;
  padding: 50px 0 30px 0;
  text-align: center;
  position: relative;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.4);
}

.cursor-circle {
  position: absolute;
  width: 150px; 
  height: 150px; 
  background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.1) 80%);
  border-radius: 50%;
  pointer-events: none; 
  transition: background 0.1s ease-out, box-shadow 0.1s ease-out;
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.3);
  margin-top: -14%;
  margin-left: -3%;
}

@media (max-width: 943px) {
  .GPTbanner-text {
    font-size: 35px;
    padding: 40px 0 23px 0;
  }
}
@media (max-width: 834px) {
  .GPTbanner-text {
    font-size: 30px;
    padding: 30px 0 20px 0;
  }
}