.blog-page {
  padding-bottom: 30px;
}

/* Navigation Bar */
.blog-nav {
  padding: 1rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin-bottom: 50px;
  border-radius: 10px;
}

.blog-nav-list {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 0;
  margin: 0;
}

.blog-nav-item {
  display: inline-block;
}

.blog-nav-link {
  color: rgb(22, 106, 234);
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}

.blog-nav-link:hover {
  border-bottom: 2px dashed #28a745;
}

/* All Blogs Section */
.all-blogs {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.blog-heading {
  text-align: center;
  font-size: 3rem;
  font-weight: 510;
  color: black;
  margin-bottom: 20px;
  padding: 50px 0px 0px 0px;
}

/* main blog section */

.blog-main-item {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 40px;
}

.blog-main-item-image {
  flex: 1.9;
  width: 60%;
  height: auto;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.blog-main-item-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-main-item-category,
.blog-main-item-title,
.blog-main-item-summary {
  margin: 0 0 15px;
}

.blog-main-item-category {
  margin: 7px 0px;
  color: rgb(22, 106, 234);
  font-size: 18px;
}

.blog-main-item-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.blog-main-item-summary {
  font-size: 1.2rem;
  color: #333;
}

.blog-main-item-link {
  text-decoration: none;
}
.blog-main-item-title:hover {
  color: rgb(22, 106, 234);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
}

/* Blog Sections */

.other-blog h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 20px 0px;
  border-bottom: 2px solid #333;
}
.blog-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.blog-item {
  margin-bottom: 20px;
  padding: 1rem;
  border-bottom: 1px solid #333;
  transition: background-color 0.3s ease;
  width: 100%;
}

/* .blog-item-image {
  margin: 0 auto;
  width: 90%;
} */
.blog-item-image {
  width: 105%; 
  height: 200px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}


.blog-item-category {
  margin: 7px 0px;
  color: rgb(22, 106, 234);
  font-size: 18px;
}

.blog-item-title {
  font-size: 1.7rem;
  color: black;
  font-weight: bold;
}

.blog-item-title:hover {
  color: rgb(22, 106, 234);
  text-decoration-line: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
}

.blog-item-summary {
  font-size: 1.2rem;
  color: #333;
  margin: 10px 0;
}

.blog-item-link {
  text-decoration: none;
}

.blog-item-link:hover {
  color: #0056b3;
}

.blog-divider {
  border: none; 
  border-top: 2px solid black; 
  margin: 40px 0; 
  margin-left: auto; 
  margin-right: auto; 
  opacity: 0.7; 
}

.blog-divider:hover {
  border-top-color: #0073e6; 
  opacity: 1; 
}

/* dark mode */
.dark-mode .blog-page {
  background-color: #010409;
}

.dark-mode .blog-heading {
  color: white;
}

.dark-mode .blog-nav {
  background-color: #161b22;
  border: 1px solid #333;
}

.dark-mode .blog-main-item-title ,.dark-mode .blog-item-title {
  color: white;
}

.dark-mode .blog-main-item-title:hover, .dark-mode .blog-item-title:hover {
  color: rgb(22, 106, 234);
}

.dark-mode .blog-main-item-summary, .dark-mode .blog-item-summary {
  color: #ccc;
}

.dark-mode .other-blog h2 {
  color: white;
}

.dark-mode .blog-item-title{
  color: white;
}

.dark-mode .blog-item-image {
  border: 1px solid #333;
}

.dark-mode .blog-divider{
  border-top: 2px solid white;
}

.dark-mode .blog-divider:hover {
  border-top-color: #0073e6; 
}

/* Responsive Design */
@media (max-width: 850px) {
  .blog-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .blog-main-item {
    flex-direction: column;
  }
  .blog-main-item-image{
    width: 100%;
  }
}

@media (max-width: 768px) {
  .blog-nav-list {
    flex-direction: column;
    gap: 1rem;
  }

  .blog-item-title {
    font-size: 1.2rem;
  }
}

@media (max-width: 420px) {
  .blog-items {
    grid-template-columns: repeat(1, 1fr);
  }
  .blog-heading{
    font-size: 2.5rem;
  }
}

@media (max-width: 355px) {
  
  .blog-heading{
    font-size: 2.3rem;
  }
}
