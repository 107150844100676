html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco", "Roboto",
    "Segoe UI", "Helvetica Neue", sans-serif;
}

.about-us-container {
  position: relative;
  overflow-x: hidden;
}

.about-us-header {
  padding: 0px 20px 20px 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.intro-section {
  margin: 5% auto 0% auto;
  animation: fadeIn 1s ease-in-out;
  width: 80%;
  text-align: center;
}

.intro-text {
  font-weight: 700;
  font-size: 3rem;
}

.about-section {
  animation: slideIn 1s ease-in-out;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}
.section-text {
  font-size: 1.3rem;
}

.about-section {
  animation: slideIn 1s ease-in-out;
  background: linear-gradient(135deg, #f3e7e9, #e3eeff);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0px auto;
  width: 95%;
}
.about-title {
  font-size: 2rem;
  font-weight: 700;
  color: #544d4f;
  color: rgb(22, 106, 234);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-align: center;
}
.about-paragraph {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #333;
  margin-top: 10px;
  padding-left: 20px;
  border-left: 5px solid #81e379;
}

.highlight-section {
  background-color: #cadbf3;
  border-radius: 30px;
  padding: 30px;
  margin: 30px auto;
  width: 85%;
}
.highlight-text {
  font-family: Poppins, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol,
    "Noto Color Emoji";
  font-size: 2rem;
  color: rgb(22, 106, 234);
  margin: 0;
  font-weight: bold;
  text-align: center;
}

.intro-passage-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, fill 0.2s,
    stroke 0.2s, opacity 0.2s, box-shadow 0.2s, transform 0.2s;
  color: #544d4f;
  margin-top: 16px;
}

.section-text span {
  display: block;
}

.our-vision-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #f3e7e9, #e3eeff) !important;
  animation: fadeInUp 1s ease-in-out;
  width: 95%;
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
}

.vision-content {
  flex: 1;
  padding-right: 20px;
  margin: 0;
}

.vision-image img {
  max-width: 100%;
}

.highlight-p{
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 8%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* dark theme */

.dark-mode .about-us-container{
  background-color: #010409;
}

.dark-mode .intro-text , .dark-mode .highlight-p,.dark-mode .section-title{
  color: white;
}

.dark-mode .intro-passage-text,.dark-mode .about-paragraph {
  color: #ddd;
}

.dark-mode .about-section {
  background: linear-gradient(135deg, #333, #161b22);
  border: 1px solid #333;
}

.dark-mode .highlight-section {
  background-color: #161b22;
  color: white;
  border: 1px solid #333;
}

.dark-mode .section-text {
  color: #ddd; 
}

.dark-mode .our-vision-section {
  background: linear-gradient(135deg, #333, #161b22) !important;
  border: 1px solid #333;
}

@media (max-width: 950px) {
  .our-vision-section {
    flex-direction: column;
  }
}