.doc-container {
  display: flex;
  flex-direction: row;
}

.doc-sidebar {
  width: 280px;
  color: black;
  padding: 20px;
  position: fixed;
  height: 100%;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  background-color: #fff;
  transition: width 0.3s ease;
}

.doc-sidebar:hover {
  width: 320px;
}

.sidebar-header {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 20px;
  color: rgb(22, 106, 234);
}

.doc-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  letter-spacing: 0.4px;
  font-size: 18px;
}

.doc-sidebar li {
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.doc-sidebar li.active {
  background-color: rgb(22, 106, 234);
  color: white;
}

.doc-sidebar li:hover {
  background-color: #f0f4f8;
  color: black;
}

.doc-sidebar ul ul {
  padding-left: 20px;
}

.doc-sidebar ul ul li {
  font-size: 0.9em;
  padding: 8px;
}

.doc-content {
  margin-left: 280px;
  padding: 40px;
  width: calc(100% - 280px);
  transition: margin-left 0.3s ease, width 0.3s ease;
}

/* Section Styles */
.doc-section {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.doc-section:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.doc-section h2 {
  border-bottom: 4px solid rgb(22, 106, 234);
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 1.75em;
  color: black;
}

.doc-section h3 {
  margin-top: 20px;
  border-bottom: 2px solid rgb(22, 106, 234);
  padding-bottom: 5px;
  font-size: 1.3em;
  color: #333;
  display: inline-block;
  width: auto;
}

.doc-section p {
  font-size: 18px;
  letter-spacing: 0.6px;
}

.doc-section p,
.doc-section ul,
.doc-section ol {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.doc-section ol li,
.doc-section ul li {
  letter-spacing: 0.4px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Links */
.doc-section a {
  color: rgb(22, 106, 234);
  text-decoration: none;
}

.doc-section a:hover {
  text-decoration: underline;
}

/* Dark theme */

.dark-mode .doc-container {
  background-color: #010409;
}
.dark-mode .doc-sidebar {
  background-color: #010409;
  color: white;
  box-shadow: 2px 0 8px rgba(255, 255, 255, 0.3);
}

.dark-mode .doc-sidebar li {
  color: white;
}

.dark-mode .doc-sidebar li:hover {
  background-color: #333;
  color: white;
}

.dark-mode .doc-content {
  background-color: #010409;
  color: white;
}

.dark-mode .doc-section {
  background-color: #161b22;
  border: 1px solid #333;
}

.dark-mode .doc-section h2 {
  color: white;
}

.dark-mode .doc-section h3 {
  color: #ddd;
}

.dark-mode .doc-section p {
  color: #ddd;
}

/* Responsiveness */

@media (max-width: 640px) {
  .doc-container {
    flex-direction: column;
  }

  .doc-sidebar {
    width: 100%;
    position: relative;
    height: 2%;
    box-shadow: none;
  }

  .doc-sidebar:hover {
    width: 100%;
  }

  .doc-content {
    margin-left: 0;
    width: 100%;
    padding: 10px;
    padding-top: 70px; 
  }

  .doc-sidebar ul {
    font-size: 14px;
  }

  .doc-sidebar ul ul li {
    font-size: 0.9em;
  }
}
