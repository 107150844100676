.overlay_banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.banner-container {
  background: linear-gradient(to right, #6b46c1, #3182ce, #ffffff);
  border-radius: 10px;
  padding: 16px;
  width: 80%;
  max-width: 400px;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  animation: pop-up 0.5s ease;
}

/* Pop-up animation */
@keyframes pop-up {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.close-button_banner {
  position: absolute;
  top: 0px;
  right: 10px;
  color: black;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Content styles */
.content_banner {
  width: 100%;
  text-align: center;
}

.title_banner {
  font-size: 1.5rem;
  font-weight: 800;
  color: #fff;
  margin-bottom: 8px;
}

.description_banner {
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 12px;
}

.explore-button {
  display: flex;
  align-items: center;
  background-color: #f6ad55;
  color: #fff;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
}

.explore-button:hover {
  background-color: #d69e2e;
}

.button-text {
  display: flex;
  align-items: center;
}

.arrow-icon {
  margin-left: 8px;
  display: inline-flex;
}

.animation-container {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .banner-container {
    max-width: 600px;
  }

  .title_banner {
    font-size: 2rem;
  }

  .description_banner {
    font-size: 1.125rem;
  }
}
@media (max-width: 450px) {
  .animation-container {
    display: none !important;
  }
  .explore-button {
    margin: 0 auto;
  }
}
