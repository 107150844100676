.ems-section {
  padding: 30px 20px;
  background: white;
  text-align: center;
}

.ems-intro {
  margin: 20px auto;
}

.ems-title {
  font-size: 3rem;
  font-weight: 700;
  color: black;
  margin: 0 auto;
  white-space: normal;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 14px;
}

.ems-description {
  font-size: 1.2rem;
  color: #333;
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.ems-about {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  gap: 10px;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.ems-image {
  max-width: 100%;
  border-radius: 10px;
  height: auto;
  object-fit: contain;
}

.ems-about-content {
  max-width: 600px;
  padding-left: 20px;
}

.ems-about-content h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.ems-about-content p {
  font-size: 1.2rem;
  color: #444;
  line-height: 1.6;
}

.ems-features {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
  width: 100%;
}

.ems-feature1,
.ems-feature2,
.ems-feature3 {
  flex: 1 1 30%;
  max-width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background 0.3s;
  position: relative;
  text-align: center;
  width: 500px;
}

.ems-feature1 {
  background: rgb(143, 163, 255);
}

.ems-feature2 {
  background: rgb(209, 230, 209);
}

.ems-feature3 {
  background: #c683ce;
}

.ems-feature1:hover,
.ems-feature2:hover,
.ems-feature3:hover {
  transform: translateY(-15px);
}

.ems-icon {
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1rem;
}

.ems-icon-monitor {
  background: #6c63ff;
}

.ems-icon-data {
  background: rgb(174, 194, 174);
}

.ems-icon-analytics {
  background: #9b61a3;
}

.ems-feature-text {
  flex: 1;
}

.ems-feature-text h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.ems-feature-text p {
  font-size: 1.1rem;
  color: #181717;
}

.ems-demo {
  margin-bottom: 30px;
  text-align: center;
  background: #f7f7f7;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.ems-demo h3 {
  font-size: 2.5rem;
  color: black;
}

.ems-demo p {
  font-size: 1.2rem;
  color: #666;
  margin-top: 1rem;
  margin-bottom: 50px;
}

.demo-button {
  display: inline-block;
  padding: 15px 35px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(22, 106, 234);
  border-radius: 10px;
  text-decoration: none;
  margin-right: 15px;
}

.demo-button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}

/* dark theme */

.dark-mode .ems-section {
  background: #010409;
  padding: 30px 20px;
}
.dark-mode .ems-title,
.dark-mode .ems-about-content h3 ,.dark-mode .ems-demo h3
{
  color: white;
}

.dark-mode .ems-description {
  color: #ccc;
}

.dark-mode .ems-about {
  background-color: #161b22;
  border: 1px solid #333;
}

.dark-mode .ems-about-content p, .dark-mode .ems-demo p {
  color: #ccc;
}

.dark-mode .ems-demo {
  background: #161b22;
  border: 1px solid #333;
}


/* Responsive Design */

@media (max-width: 1024px) {
  .ems-section {
    padding: 0px 10px;
  }

  .ems-title {
    font-size: 2.5rem;
    line-height: 1.3;
  }

  .ems-description {
    font-size: 1.1rem;
  }

  .ems-feature1,
  .ems-feature2,
  .ems-feature3 {
    flex: 1 1 45%;
    max-width: 45%;
    margin-bottom: 1.5rem;
  }

  .ems-feature-text h3 {
    font-size: 1.6rem;
  }

  .ems-feature-text p {
    font-size: 1rem;
  }

  .ems-demo h3 {
    font-size: 2.2rem;
  }

  .ems-demo p {
    font-size: 1.1rem;
  }

  .demo-button {
    padding: 12px 25px;
  }
}

@media (max-width: 768px) {
  .ems-title {
    font-size: 2rem;
    line-height: 1.4;
    padding: 0 1rem;
  }

  .ems-description {
    font-size: 1rem;
  }

  .ems-feature1,
  .ems-feature2,
  .ems-feature3 {
    flex: 1 1 100%;
    max-width: 700px;
    margin-bottom: 1.5rem;
  }

  .ems-icon {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .ems-feature-text h3 {
    font-size: 1.4rem;
  }

  .ems-feature-text p {
    font-size: 0.9rem;
  }

  .ems-demo {
    padding: 2rem 1rem;
  }

  .ems-demo h3 {
    font-size: 2rem;
  }

  .ems-demo p {
    font-size: 1rem;
  }

  .demo-button {
    padding: 10px 20px;
  }
  .ems-about {
    display: flex;
    flex-direction: column;
  }
  .ems-about-image img{
    width: 50%;
  }
}

@media (max-width: 480px) {
  .ems-title {
    font-size: 2rem;
    line-height: 1.5;
    padding: 0 0.5rem;
    margin-bottom: 20px;
  }
  .ems-about {
    display: flex;
    flex-direction: column;
  }
}