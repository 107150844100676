.probe-header {
  padding: 60px 20px 10px 20px;
  margin: 0% auto;
  text-align: center;
  width: 80%;
  letter-spacing: 0.4px;
  border-bottom: 1px solid #777;
}

.probe-main-title {
  font-size: 3rem;
  font-weight: 700;
  color: black;
  margin: 0 auto;
  white-space: normal;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 14px;
}

.probe-subtitle {
  font-size: 24px;
  animation: blink 1.5s infinite;
  margin-bottom: 20px;
  color: #555;
}
@keyframes blink {
  0% {
    color: rgb(22, 106, 234);
  }
  50% {
    color: #28a745;
  }
  100% {
    color: rgb(22, 106, 234);
  }
}

.probe-header-description {
  font-size: 18px;
  line-height: 1.5;
  color: #444;
  text-align: center;
}

.probe-intro {
  padding: 40px 20px 0px 20px;
  margin: 0px auto;
  width: 80%;
  letter-spacing: 0.4px;
}
.probe-title {
  font-size: 2.3rem;
  margin-bottom: 20px;
  text-align: center;
  color: black;
  font-weight: bold;
}

.probe-description {
  font-size: 18px;
  line-height: 1.5;
  color: #444;
  text-align: center;
}
.probe-feature-title {
  font-size: 22px;
  color: #222;
}

.probe-feature-description {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.probe-cta {
  padding: 60px 20px;
  text-align: center;
  background-color: #f0f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 30px auto;
  width: 80%;
}

.probe-cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.probe-cta-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: black;
}

.probe-cta-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
  color: #444;
}

.cta-button {
  display: inline-block;
  padding: 15px 35px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(22, 106, 234);
  border-radius: 10px;
  text-decoration: none;
  margin-right: 15px;
}

.cta-button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}

.probe-cta-testimonial {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  margin-top: 30px;
}

.probe-cta-testimonial strong {
  color: rgb(22, 106, 234);
}

.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
}
.feature-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.feature-card1 {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  height: 380px;
}
.feature-card1:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.probe-why-choose-us {
  padding: 40px 20px;
  margin: 30px auto;
  width: 80%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.probe-why-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.probe-content-left {
  flex: 1;
  margin-right: 20px;
}

.probe-image {
  width: 110%;
}

.probe-content-right {
  flex: 2;
}

.probe-why-title {
  font-size: 36px;
  margin-bottom: 20px;
  color: #222;
}

.probe-why-description {
  font-size: 18px;
  line-height: 1.6;
  color: #444;
  margin-bottom: 20px;
}

.probe-why-list {
  list-style: none;
  padding: 0;
  margin: 20px auto 0;
  width: 100%;
}

.probe-why-list li {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

/* dark theme */

.dark-mode .probe-page {
  background-color: #010409;
  padding-bottom: 20px;
}

.dark-mode .probe-main-title,
.dark-mode .probe-title,
.dark-mode .probe-why-title,
.dark-mode .probe-cta-title,
.dark-mode .probe-cta-testimonial {
  color: white;
}

.dark-mode .probe-header-description,
.dark-mode .probe-description,
.dark-mode .probe-why-description,
.dark-mode .probe-cta-description {
  color: #ccc;
}

.dark-mode .probe-why-choose-us,
.dark-mode .probe-cta {
  background-color: #161b22;
  border: 1px solid #333;
}
.dark-mode .probe-why-list li {
  color: white;
}

/* Responsive Styles */

@media (max-width: 950px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .probe-why-content {
    flex-direction: column;
    align-items: center;
  }

  .probe-content-left {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .probe-content-right {
    text-align: center;
  }

  .probe-why-title {
    font-size: 32px;
  }

  .probe-why-description {
    font-size: 16px;
  }

  .probe-why-list li {
    font-size: 16px;
  }
}

@media (max-width: 570px) {
  .card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .probe-why-title {
    font-size: 28px;
  }

  .probe-why-description {
    font-size: 14px;
  }

  .probe-why-list li {
    font-size: 14px;
  }
}
