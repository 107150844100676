.side-content {
  padding: 30px;
  background-color: #ffffff;

  margin: 20px auto;
  max-width: 1200px;
}

.top-section {
  text-align: center;
  margin-bottom: 30px;
}

.top-heading {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #000000; 
  font-weight: bold;
  line-height: 1.2;
}

.top-subheading {
  font-size: 1.4em;
  color: #666;
  max-width: 80%;
  margin: 0 auto;
}

.product-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.product {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.product.visible {
  opacity: 1;
  transform: translateY(0);
}

.product.reverse {
  flex-direction: row-reverse;
}

.product-text {
  flex: 1;
  padding: 25px;
  background: #ffffff;
  text-align: center;
}

.product-name {
  font-size: 1.8em;
  color: #333;
  font-weight: bold;
}

.product p {
  margin-bottom: 12px;
  color: #666;
  font-size: 1em;
}

.explore-link {
  color: rgb(22,106,234); 
  text-decoration: none;
  font-weight: bold;
  position: relative;
  padding-bottom: 5px;
}
/* 
.explore-link::after {
  content: '>';
  position: absolute;
  right: -25px;
  color: #5068f2;
  font-size: 1.2em;
} */

.product-image {
  max-width: 250px;
  height: 200px;
  border-radius: 10px;
}
.gptimage {
  height: 170px;
}

/* dark theme */

.dark-mode .side-content {
  background-color: #010409; 
  color: white; 
}

.dark-mode .top-heading {
  color: white; 
}

.dark-mode .top-subheading {
  color: #ccc;
}

.dark-mode .product-text{
  background-color: #161b22; 
}

.dark-mode .product{
  background-color: #161b22; 
  border: 1px solid #333;
}

.dark-mode .product-name {
  color: white; 
}

.dark-mode .product p {
  color: #ccc; 
}

.dark-mode .explore-link {
  color: rgb(22,106,234); 
}


/* Responsive Design */
@media (max-width: 768px) {
  .dark-mode .top-heading {
    font-size: 2em;
  }

  .dark-mode .top-subheading {
    font-size: 1.2em;
  }

  .dark-mode .product-image {
    max-width: 200px;
  }
}


@media (min-width: 768px) {
  .product-container {
    flex-direction: row;
    gap: 40px;
  }

  .product {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .top-heading {
    font-size: 2em;
  }

  .top-subheading {
    font-size: 1.2em;
  }

  .product-container {
    flex-direction: column;
  }

  .product {
    flex-direction: column;
    text-align: center;
  }

  .product-image {
    max-width: 200px;
  }
}
