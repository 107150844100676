/* Partner Container Styles */
.partner-container {
  position: relative;
  margin: auto;
  padding: 0px 20px 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;

  /* background: linear-gradient(135deg, #5068f2, #908df2); */
}

/* Partner Content Styles */
.partner-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  /* background: linear-gradient(135deg, #f7f7f7, #e3ebf2);  */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px #999;
  animation: fadeIn 1s ease; /* Smooth fade-in animation */
}

/* Partner Logo Styles */
.partner-logo img {
  max-width: 200px;
  height: auto;
  object-fit: contain;
  animation: zoomIn 1s ease infinite alternate;
}

/* Partner Details Styles */
.partner-details {
  flex: 1;
  margin-top: 20px;
  text-align: center;
}

.partner-details h2 {
  font-size: 2.5rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 20px;
  animation: fadeIn 1s ease;
}

.partner-details p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  animation: fadeIn 1s ease;
}

/* Keyframes for animations */
@keyframes zoomIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dark Theme */

.dark-mode .partner-container {
  background-color: #010409;
  color: white;
}

.dark-mode .partner-content {
  background-color: #161b22;
  box-shadow: none;
  border: 1px solid #333;
}

.dark-mode .partner-details h2 {
  color: #ffffff;
}

.dark-mode .partner-details p {
  color: #ddd;
}

.dark-mode .partner-logo img{
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}

/* Responsive Design */
@media (min-width: 768px) {
  .partner-content {
    flex-direction: row;
    text-align: left;
  }

  .partner-details {
    margin-left: 40px;
    margin-top: 0;
  }

  .partner-logo img {
    max-width: 300px;
  }
}
