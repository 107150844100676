.usecases-section {
  text-align: center;
}

.usecases-main-title {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.usecases-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.usecases-row {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.usecases-row:hover {
  transform: translateY(-10px);
}

.usecases-column {
  flex: 1;
  margin: 10px 0;
}

.usecase-content {
  padding: 10px;
}

.usecase-content.topic h5 {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(22, 106, 234);
  margin-bottom: 10px;
}

.usecase-content.without-ardra,
.usecase-content.with-ardra {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  height: 100%;
}

.usecase-content.without-ardra {
  border-left: 4px solid #e74c3c;
}

.usecase-content.with-ardra {
  border-left: 4px solid #2ecc71;
  position: relative;
}

.usecase-description {
  font-size: 1rem;
  color: #666;
}

/* Dark Theme Styles */
.dark-mode .usecases-section {
  background-color: #010409;
  color: white;
}

.dark-mode .usecases-main-title {
  color: white;
}

.dark-mode .usecases-row {
  background-color: #161b22;
  border: 1px solid #333;
}

.dark-mode .usecase-description {
  color: #ddd;
}

@media (min-width: 768px) {
  .usecases-row {
    flex-direction: row;
  }

  .usecases-column {
    margin: 0 15px;
  }

  .usecase-content.topic h5 {
    font-size: 1.75rem;
  }

  .usecase-description {
    font-size: 1.125rem;
  }

  .usecases-main-title {
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  .usecases-main-title {
    font-size: 2.3rem;
  }

  .usecase-content.topic h5 {
    font-size: 1.5rem;
  }

  .usecase-description {
    font-size: 1.25rem;
  }
}

@media (min-width: 600px) {
  .usecases-main-title {
    font-size: 2.5rem;
  }
}
