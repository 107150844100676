.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  background: none;
  border: none;
  color: #000;
  margin: 0 5px;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  font-weight: bold;
}

.pagination-button.active {
  color: rgb(22,106,234); 
  text-decoration: underline;
}

.pagination-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.pagination-button:hover:not(.active):not(:disabled) {
  color: rgb(36, 122, 250); 
}

.pagination-button.arrow {
  font-size: 20px;
}

/* dark mode */
.dark-mode .pagination-button{
  color: white;
}

.dark-mode .pagination-button.active {
  color: rgb(22,106,234); 
}