/* General Styles */
.feature-grid {
  padding: 5rem 2rem;
  background-color: #ffffff;
}

/* Feature Intro Section Styles */
.feature-intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
}

.features-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding: 20px;
  color: #000000;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.features-intro {
  padding: 20px;
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .feature-intro-section {
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: space-between;
  }
  .features-title {
    margin-bottom: 0;
  }
  .learn-more {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .learn-more {
    margin-top: 1rem;
  }
}

/* Feature Icon Styles */
.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background: #f7f7f7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
}

.feature-icon i {
  text-align: center;
  font-size: 40px;
  color: rgb(22, 106, 234);
  transition: color 0.3s ease;
}

.feature-wrap:hover .feature-icon {
  transform: scale(1.1) rotate(10deg);
  background: rgb(22, 106, 234);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-wrap:hover .feature-icon i {
  color: #fff;
}

.feature-wrap {
  border: 1px solid #e6e6e6;
  padding: 40px 30px;
  background: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  text-align: center;
}

.feature-wrap:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.feature-wrap:hover:before {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  content: "";
  opacity: 1;
  z-index: 1;
  pointer-events: none;
}

.feature-wrap h4,
.feature-wrap p {
  z-index: 2;
}

.feature-wrap:hover h4,
.feature-wrap:hover p {
  color: #fff;
}

.feature-wrap h4 {
  font-weight: 600;
  color: #1e2331;
  margin-bottom: 17px;
  font-size: 1.3rem;
  text-transform: capitalize;
  transition: color 0.3s ease;
  position: relative;
}

.feature-wrap p {
  color: #555;
  transition: color 0.3s ease;
  position: relative;
}

.feature-carousel {
  padding: 20px;
}
.lottie-container img {
  width: 110%;
}
/* Swiper Styles */
.swiper-slide {
  display: flex;
  align-items: stretch;
  justify-content: center;
}


/* Dark Mode Styles */
.dark-mode .feature-carousel {
  background-color: #010409;
}

.dark-mode .features-title {
  color: white; 
}

.dark-mode .features-intro {
  color: #ddd; 
}

.dark-mode .feature-wrap {
  background-color: #161b22;
  border-color: #333; 
}

.dark-mode .feature-wrap h4 {
  color: white; 
}

.dark-mode .feature-wrap p {
  color: #cccccc;
}

.dark-mode .feature-icon {
  background-color: white; 
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); 
}

.dark-mode .feature-wrap:hover {
  box-shadow: 0 20px 40px rgba(255, 255, 255, 0.1); 
}

.dark-mode .feature-wrap:hover:before {
  background: rgba(255, 255, 255, 0.1);
}

@media (max-width: 576px) {
  .feature-wrap {
    padding: 20px 15px;
  }

  .feature-icon {
    width: 50px;
    height: 50px;
  }

  .feature-icon i {
    font-size: 30px;
  }

  .feature-wrap h4 {
    font-size: 16px;
  }

  .feature-wrap p {
    font-size: 14px;
  }
}
@media (max-width: 960px) {
  .features-title {
    font-size: 40px;
  }
  .lottie-container img {
    width: 120%;
  }
}
@media (max-width: 763px) {
  .features-title {
    font-size: 30px;
  }
  .lottie-container img {
    width: 60%;
  }
}
@media (max-width: 490px) {
  .lottie-container img {
    width: 70%;
  }
}