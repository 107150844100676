.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.faq-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
}

.faq {
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  background: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.faq-question:hover {
  background: #e9e9e9;
}

.faq-question span {
  font-size: 16px;
}

.faq-toggle i {
  font-size: 16px;
  transition: transform 0.3s ease;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  padding: 0 15px;
  transition:
    max-height 0.3s ease,
    padding 0.3s ease;
  background: #fff;
}

.faq-answer.expanded {
  max-height: 200px; /* Adjust based on your content */
  padding: 10px 15px;
}

.custom-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
}

.custom-list li {
  position: relative;
  padding-left: 25px; /* Space for the blue border */
  margin-bottom: 10px;
}

.custom-list li:before {
  content: "★"; /* Star icon */
  position: absolute;
  left: 0;
  top: 0;
  color: rgb(22,106,234); /* Blue color for the star */
}

.custom-list li:nth-of-type(odd):before {
  color: rgb(22,106,234); /* Blue color for odd items */
}

.custom-list li:nth-of-type(even):before {
  color: rgb(22,106,234); /* Blue color for even items */
}

.show-more-button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 15px;
  color: rgb(22,106,234);
  cursor: pointer;
  margin: 20px 0;
  position: relative;
}

.show-more-text {
  display: inline-block;
  text-decoration: underline;
  color: black;
  margin-right: 5px;
  color: rgb(22,106,234);
  font-weight: bold;
}

.click-here {
  color: rgb(22,106,234);
  font-weight: bold;
  margin-right: 5px;
}

.icon {
  font-size: 16px;
  transition: color 0.3s ease;
}

/* dark theme */

.dark-mode .faq-container h2 {
  color: #ffffff;
}

.dark-mode .faq {
  border: 1px solid #333;
}

.dark-mode .faq-question {
  background: #161b22;
  border-bottom: 1px solid #444;
  color: #ddd;
  
}

.dark-mode .faq-question:hover {
  background: #1d242b;
}

.dark-mode .faq-answer {
  background: #101317;
  color: #ccc;
}

.dark-mode .custom-list li:before {
  color: rgb(22,106,234); /* Adjusted for better contrast in dark mode */
}

.dark-mode .show-more-text,
.dark-mode .click-here,
.dark-mode .icon {
  color: rgb(22,106,234); /* Adjusted for better contrast in dark mode */
}

.dark-mode .show-more-button:hover .icon {
  color: rgb(22,106,234);
}
