.navbar {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between; /* Ensure space between logo and right-side elements */
  position: relative;
}

.navbar-scrolled {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  flex: 1;
}

.logo-img {
  height: 65px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
}

/* Menu and Actions */
.navbar-collapse {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
}
.navbar-actions {
  width: auto;
  align-items: center;
  flex-direction: row;
  padding: 0;
  margin: 0;
  gap: 25px; /* Reduced gap for better alignment */
}

.navbar-actions img {
  width: 30px;
  height: 20px;
}

.navbar-collapse.show {
  display: flex;
}

.navbar-menu {
  flex-direction: column;
  width: 75%;
  align-items: center;
  padding: 0;
  margin: 0;
}

.navbar-item {
  list-style: none;
  position: relative;
}

.navbar-link {
  display: block;
  padding: 1rem;
  text-decoration: none;
  color: black;
}

/* Dropdown Menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
  border-radius: 10px;
}

.navbar-item:hover .dropdown-menu,
.dropdown-menu.show {
  display: block; /* Show dropdown on hover or when toggled */
}
.navbar-link:hover,
.dropdown-item:hover {
  color: rgb(22, 106, 234);
}

.dropdown-item:hover {
  border-radius: 10px;
  background-color: rgb(249, 249, 249);
}
.navbar-button1 {
  color: black;
  font-size: 18px;
  transition: color 0.3s ease, transform 0.3s ease, text-decoration 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.navbar-button1:hover {
  transform: scale(1.1);
  color: rgb(22, 106, 234);
  border-bottom: 2px solid rgb(22, 106, 234);
}

.navbar-button2 {
  color: white;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 15px;
  border-radius: 10px;
  background-color: #28a745;
  transition: background-color 0.3s ease, color 0.3s ease;
  min-width: 150px;
}
.navbar-button2:hover {
  background-color: #1bc643;
  color: #fff;
}

.dropdown-item {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  color: black;
}

.navbar-collapse ul {
  display: flex;
}

.dropdown-header {
  border-bottom: 2px solid #86b3f6;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1.25rem;
}

.theme-toggle-container {
  margin-left: 10px;
}

/* Basic styles for dropdown */
.dropdown-icon {
  transition: transform 0.3s ease;
}

.navbar-item:hover .dropdown-icon,
.navbar-item.active .dropdown-icon {
  content: "\f078";
  transform: rotate(180deg);
}

.fa-chevron-down,
.fa-chevron-up {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

/* dark theme  */

.dark-mode .navbar {
  background-color: #010409;
  color: white;
}

.dark-mode .navbar-button1 {
  color: white;
}

.dark-mode .navbar-button1:hover {
  color: rgb(22, 106, 234);
  border-bottom: 2px solid rgb(22, 106, 234);
}

.dark-mode .navbar-link,
.dark-mode .dropdown-item {
  color: white;
}

.dark-mode .navbar-link:hover,
.dark-mode .dropdown-item:hover {
  color: rgb(22, 106, 234);
}

.dark-mode .dropdown-menu {
  background-color: #161b22;
  border-radius: 10px;
  border: 1px solid #333;
}

.dark-mode .dropdown-header {
  color: rgb(255, 255, 255);
}

.dark-mode .navbar-toggler {
  color: white;
}

.dark-mode .navbar-scrolled {
  background-color: rgba(25, 25, 25, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Media Queries */
@media (max-width: 1187px) {
  .navbar-actions {
    gap: 15px;
  }
}
@media (max-width: 1107px) {
  .navbar-actions {
    gap: 10px;
  }
}
@media (max-width: 1067px) {
  .navbar-actions {
    gap: 10px;
  }
  .navbar-menu {
    width: 73%;
  }
}
@media (max-width: 1030px) {
  .navbar-actions {
    gap: 15px;
  }
  .navbar-menu {
    width: 70%;
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    /* height: 340px; */
    padding-bottom: 30px;
  }

  .navbar-toggler {
    display: block;
  }

  .navbar-right {
    display: flex;
    align-items: center;
    position: relative;
  }

  .navbar-menu,
  .navbar-actions {
    width: 100%;
    text-align: center;
  }

  .navbar-actions {
    margin-top: 1rem;
    flex-direction: column !important;
  }

  .theme-toggle-container {
    order: 2; /* Ensure the theme toggle is on the right side */
  }

  .navbar-toggler {
    order: 1; /* Ensure hamburger menu is close to the theme toggle button */
  }

  .dark-mode .navbar-collapse {
    background-color: black;
  }

  /* new added */
  .dropdown-menu {
    display: none;
    padding: 15px;
    width: 100%;
    /* left: -50px; */
  }

  .navbar-item:hover .dropdown-menu,
  .navbar-item.active .dropdown-menu,
  .dropdown-menu.show {
    display: block;
    position: relative;
  }
}

@media (min-width: 992px) {
  .navbar-collapse {
    display: flex; /* Show menu by default on large screens */
    position: static;
    flex-direction: row;
    background: none;
    box-shadow: none;
  }

  .navbar-menu {
    flex-direction: row;
  }

  .navbar-actions {
    display: flex;
    align-items: center;
  }

  .navbar-toggler {
    display: none;
  }
}
