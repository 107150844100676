/* Container */
.terms-container {
  padding: 20px;
  max-width: 800px;
  margin: 40px auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-family: "Arial", sans-serif;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.terms-heading {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
}

.terms-meta {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
  text-align: center;
}

.subheading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #0056b3;
  /* border-left: 4px solid #0056b3; */
  padding-left: 10px;
}

.subheading2 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #007bff;
}

.terms-para {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 10px;
}

.terms-point {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 10px;
}

.terms-subpoint {
  margin-bottom: 5px;
}

.terms-link {
  color: #007bff;
  text-decoration: none;
}

.terms-link:hover {
  color: #1abc9c;
  text-decoration: underline;
}

.terms-container strong {
  color: #222;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .terms-container {
    padding: 15px;
  }

  .terms-heading {
    font-size: 1.8rem;
  }

  .subheading {
    font-size: 1.3rem;
  }

  .subheading2 {
    font-size: 1.1rem;
  }

  .terms-para {
    font-size: 0.95rem;
  }
}
