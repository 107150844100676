.table-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
}

.table-container h3 {
  color: #9f9fa5;
  font-size: 22px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}

.table-container h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  color: black;
}

.table-container p {
  color: #9f9fa5;
  font-size: 22px;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #151535;
  border-radius: 8px;
  overflow: hidden;
}

thead th {
  background: linear-gradient(90deg, #3e4fe0, #4b58f0);
  color: #fff;
  padding: 15px 10px;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid transparent;
  transition: background 0.3s ease;
}

thead th:first-child {
  border-top-left-radius: 8px;
}

thead th:last-child {
  border-top-right-radius: 8px;
}

thead th:hover {
  background: linear-gradient(90deg, #4b58f0, #5c68ff);
}

tbody td {
  background-color: #1d1d2d;
  padding: 15px 10px;
  border: 1px solid #2a2a3d;
  font-size: 16px;
  transition: background 0.3s ease, transform 0.3s ease;
}

tbody tr {
  transition: transform 0.3s ease;
}

tbody tr:hover {
  background-color: #252545;
  transform: scale(1.02);
}

tbody tr:nth-child(even) td {
  background-color: #252545;
}

tbody td:first-child {
  border-left: none;
}

tbody td:last-child {
  border-right: none;
}

/* dark theme */

.dark-mode .table-container {
  background-color: #010409; 
  color: white; 
}

.dark-mode .table-container h3,
.dark-mode .table-container p {
  color: white; 
}

.dark-mode .table-container h1 {
  color: white;
}

.dark-mode table {
  background: #161b22; 
}

.dark-mode thead th {
  background: linear-gradient(90deg, #3e4fe0, #4b58f0);
  color: #fff; 
}

.dark-mode thead th:hover {
  background: linear-gradient(90deg, #4b58f0, #5c68ff); 
}

.dark-mode tbody td {
  background-color: #0d1117; 
  color: #e3e3e3;
}

.dark-mode tbody tr:hover {
  background-color: #161b22;
}

.dark-mode tbody tr:nth-child(even) td {
  background-color: #1b1f2a; 
}

.dark-mode tbody td:first-child,
.dark-mode tbody td:last-child {
  border-left: none;
  border-right: none;
}


@media screen and (max-width: 768px) {
  /* Hide Blackberry and Aliste columns */
  table thead th:nth-child(3),
  table thead th:nth-child(4),
  table tbody td:nth-child(3),
  table tbody td:nth-child(4) {
      display: none;
  }

  .table-container {
      padding: 10px;
  }

}
