.theme-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.theme-toggle input[type="checkbox"] {
  display: none;
}

.theme-toggle label {
  display: flex;
  align-items: center;
  position: relative;
  width: 40px; /* Width of the switch */
  height: 40px; /* Height of the switch */
  /* background: #ddd; */
  border-radius: 50%;
  transition: background 0.3s;
}


.theme-toggle input[type="checkbox"]:checked + label {
  cursor: pointer;
}

.theme-toggle input[type="checkbox"]:checked + label::before {
  cursor: pointer;
}

.theme-toggle .toggle-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  transition: opacity 0.3s;
  cursor: pointer;
}

.theme-toggle .toggle-icon.hide {
  opacity: 0;
}

.theme-toggle .toggle-icon.show {
  opacity: 1;
}

.toggle-icon.moon{
font-size: 35px;
}