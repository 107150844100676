.subscribe-container {
  position: relative;
}

.subscribe-button {
  padding: 12px 60px;
  border: none;
  border-radius: 10px;
  background-color: rgb(22, 106, 234);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.1rem;
  margin-bottom: 4%;
}

.subscribe-button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}
.subscribe-button i{
  font-size: 23px;
}
/* Popup */
.subscribe_popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.subscribe_popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 350px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 2px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.subscribe-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.subscribe_title {
  font-size: 1.7rem;
}

.subscribe-input {
  width: 300px;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: all 0.3s ease;
  margin: 0 auto;
}

.subscribe-input:focus {
  outline: none;
  border-color: rgb(22, 106, 234);
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}


.submit-button {
  width: 300px;
  padding: 12px 60px;
  border: none;
  border-radius: 10px;
  background-color: rgb(22, 106, 234);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.1rem;
  margin: 0 auto;
}

.submit-button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}

@media (max-width: 768px) {
  .subscribe-input,
  .submit-button {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .subscribe-input,
  .submit-button {
    width: 90%;
  }
}
