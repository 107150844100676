.testimonials-section {
  text-align: center;
}

.testimonials-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 40px;
  position: relative;
}

.testimonials-heading::after {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  margin: 10px auto;
  border-radius: 2px;
  animation: slideIn 1s ease-in-out;
}

.testimonials-carousel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 10px;
  animation: fadeIn 2s ease-in-out;
}

.testimonial-card {
  background-color: #f0f8ff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 30px;
  max-width: 500px;
  text-align: left;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.testimonial-feedback {
  font-size: 1.2rem;
  font-style: italic;
  color: #555;
  margin-bottom: 20px;
  animation: fadeInText 1.5s ease-in-out;
}

.testimonial-author h3 {
  font-size: 1.3rem;
  color: rgb(22, 106, 234);
  margin-bottom: 5px;
}

.testimonial-author p {
  font-size: 1rem;
  color: #888;
}

@keyframes slideIn {
  from {
    width: 0;
  }
  to {
    width: 50px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dark Theme */

.dark-mode .testimonials-section {
  background-color: #010409;
  color: white;
}

.dark-mode .testimonials-heading {
  color: #ffffff;
}

.dark-mode .testimonials-heading::after {
  background-color: #ffffff;
}

.dark-mode .testimonial-card {
  background-color: #161b22;
  color: white;
  border: 1px solid #333;
}

.dark-mode .testimonial-feedback {
  color: #ddd;
}

.dark-mode .testimonial-author h3 {
  color: rgb(22, 106, 234);
}

.dark-mode .testimonial-author p {
  color: #ccc;
}


/* Responsive Design */

@media (max-width: 768px) {
  .testimonials-heading {
    font-size: 2rem;
  }

  .testimonial-card {
    max-width: 90%;
    padding: 20px;
  }

  .testimonials-carousel {
    flex-direction: column;
    align-items: center;
  }
}
