.energygpt-section {
  padding: 50px 0px 30px 0px;
  color: #333;
}

.energygpt-intro {
  text-align: center;
  margin-bottom: 2rem;
}

.energygpt-title {
  /* font-size: 2.5rem; */
  font-size: 3rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 1rem;
}

.energygpt-description {
  font-size: 1.7rem;
  color: rgb(22, 106, 234);
}

/* Content Alignment */
.energygpt-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.energygpt-text {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.6;
}

/* Features Section */
.energygpt-features {
  padding: 2rem;
  border-radius: 8px;
}

.energygpt-features h3 {
  font-size: 2.3rem;
  margin-bottom: 2rem;
  color: #000;
  text-align: center;
}

.energyGPT-feature-wrap {
  position: relative;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #333;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.energyGPT-feature-wrap:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.energyGPT-icon {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  background: rgb(22, 106, 234);
  border-radius: 50%;
  height: 60px;
  width: 60px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.energyGPT-feature-wrap:nth-child(1) .energyGPT-icon {
  background-color: rgb(22, 106, 234);
}

.energyGPT-feature-wrap:nth-child(2) .energyGPT-icon {
  background-color: rgb(183, 223, 183);
}

.energyGPT-feature-wrap:nth-child(3) .energyGPT-icon {
  background-color: #c683ce;
}

.energyGPT-feature-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.energyGPT-feature-description {
  font-size: 1rem;
}

/* How EnergyGPT Works Section */
.energygpt-how-it-works {
  margin: 2rem 0;
}

.energygpt-how-it-works h3 {
  /* font-size: 1.75rem; */
  font-size: 2.3rem;
  margin-bottom: 1rem;
  /* font-weight: bold; */
  color: #000000;
  text-align: center;
}

.energygpt-how-it-works-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.energygpt-how-it-works-content > div {
  margin: 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 30%;
  min-width: 250px;
  text-align: center;
}

.energygpt-how-it-works-content i {
  font-size: 1.5rem;
  color: #fff;
  background: rgb(22, 106, 234);
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.energygpt-how-it-works-content > div:nth-child(2) i {
  background-color: rgb(65, 166, 65);
}

.energygpt-how-it-works-content > div:nth-child(3) i {
  background-color: #8d3597;
}

.energygpt-how-it-works-content p {
  font-size: 1rem;
  color: #555;
  margin-top: 0.5rem;
}

/* Footer Section */
.energygpt-footer {
  text-align: center;
  margin: 2rem 0;
  background-color: #f0f4f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 60px 20px;
}

.energygpt-footer h3 {
  font-size: 2.3rem;
  margin-bottom: 1rem;
  color: #000000;
}

.energygpt-footer p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.energygpt-demo-button {
  display: inline-block;
  padding: 15px 35px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border: none;
  background-color: rgb(22, 106, 234);
  border-radius: 10px;
  text-decoration: none;
  margin-right: 15px;
}

.energygpt-demo-button:hover {
  background-color: rgb(36, 122, 250);
  transform: translateY(-3px);
}

/* dark theme */

.dark-mode .energygpt-section {
  background-color: #010409;
}

.dark-mode .energygpt-title,
.dark-mode .energygpt-features h3,
.dark-mode .energyGPT-feature-wrap h4,
.dark-mode .energygpt-how-it-works h3,
.dark-mode .energygpt-footer h3 {
  color: white;
}

.dark-mode .energygpt-content,
.dark-mode .energyGPT-feature-wrap p,
.dark-mode .energygpt-footer p {
  color: #ccc;
}

.dark-mode .energyGPT-feature-wrap,
.dark-mode .energygpt-footer {
  background-color: #161b22;
  border: 1px solid #333;
}

.dark-mode .swiper-pagination-bullet {
  background-color: white;
}

.dark-mode .energygpt-how-it-works-content p {
  color: black;
}

/* new added section 1*/

.energygpt-link-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  /* padding: 30px; */
  border-radius: 10px;
  background: linear-gradient(135deg, #0a2e73, #6a5acd, #8a2be2);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;
}

.energygpt-link-section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-100%);
  transition: transform 0.5s ease;
  pointer-events: none;
}

.energygpt-link-content {
  flex: 1;
  text-align: left;
  padding-right: 10px; /* Adds space between video and text */
}

.energygpt-link-video {
  flex: 0 0 600px; 
  margin-right: -30px;
  padding-left: 0px;
}

.energygpt-link-section h3 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: bold;
  /* margin-bottom: 10px; */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  animation: move-heading 3s infinite ease-in-out,
    text-glow 3s infinite ease-in-out;
}

.energygpt-link-section p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.energygpt-link-button {
  display: inline-block;
  padding: 15px 35px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background: linear-gradient(135deg, #013698, hsl(275, 97%, 36%), #8a2be2);
  box-shadow: 1px 4px 10px rgba(13, 77, 194, 0.5),
    1px 8px 20px rgba(106, 3, 180, 0.4), 1px 12px 30px rgba(138, 43, 226, 0.3);
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.energygpt-link-button:hover {
  background-color: rgba(36, 122, 250, 0.9);
  transform: translateY(-5px) scale(1.05);
  animation: pulse 1s infinite;
}

/* Pulse animation on hover */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.energygpt-link-button::after {
  content: "→";
  display: inline-block;
  margin-left: 10px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.energygpt-link-button:hover::after {
  opacity: 1;
  transform: translateX(10px);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .energygpt-link-section {
    flex-direction: column;
    text-align: center;
  }

  .energygpt-link-video {
    margin-bottom: 20px;
    flex: 0;
  }

  .energygpt-link-content {
    padding-right: 0;
    text-align: center;
  }
}
